<template>
  <header class="header">
    <div class="header-inner">
      <div class="header-avatar">
      </div>

      <div id="nav"
           class="hidden-xs-only">
        <router-link to="/"><i class="el-icon-guide"></i> 主页</router-link>
        <!-- <router-link to="/photo"><i class="el-icon-picture-outline"></i> 照片墙</router-link> -->
        <router-link to="/articles"><i class="el-icon-reading"></i> 文档库</router-link>
        <router-link to="/tools"><i class="el-icon-chicken"></i> 小工具</router-link>
        <router-link to="/about"><i class="el-icon-user"></i> 关于我</router-link>
      </div>
      <div id="nav1"
           class="hidden-sm-and-up">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-more"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/"><i class="el-icon-guide"></i> 主页</router-link>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <router-link to="/photo"><i class="el-icon-picture-outline"></i> 照片墙</router-link>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <router-link to="/articles"><i class="el-icon-reading"></i> 文档库</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/tools"><i class="el-icon-chicken"></i> 小工具</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/about"><i class="el-icon-user"></i> 关于我</router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>

  <div class="home">
    <div class="mine">
      <div>
        <p>你的指尖</p>
        <p>有改变世界的力量</p>
      </div>
      <img src="../../assets/mine.jpeg"
           alt="">
    </div>
  </div>

  <home-footer></home-footer>
</template>

<script>
// import { nextTick } from '@vue/runtime-core'
import 'element-plus/lib/theme-chalk/display.css'
import homeFooter from '../../components/Footer'

export default {
  components: {
    homeFooter
  }
}
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  left: 0;
  right: 0;
  height: 72px;
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-avatar {
  flex: 1;
}

#nav {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#nav1 {
  width: 60px;
  display: flex;
  align-items: center;
}

#nav1 .el-dropdown-link i {
  font-size: 24px;
}

#nav a {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.mine {
  display: flex;
  align-items: center;
  height: 480px;
  background: #9fe2bf;

  div {
    flex: 1;
    margin-left: 15%;

    p {
      font-size: 40px;
      font-weight: bold;
      color: white;
    }
  }

  img {
    margin-right: 15%;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
}

.footer {
  margin-top: 160px;
}

.footer div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 14px;
  color: #666;
}

.footer span {
  margin: 0 16px;
}

.footer a {
  color: #42b983;
  text-decoration: none;
  margin: 0 8px;
}
</style>
