import { createApp } from 'vue'
import {
  ElButton,
  ElCol,
  ElColorPicker,
  ElIcon,
  ElInput,
  ElRadio,
  ElRadioGroup,
  ElRow,
  ElSlider,
  ElMessage,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElUpload,
  ElImage,
  ElMessageBox,
  ElSkeleton
} from 'element-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/common.css'

import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'

import VMdEditor from '@kangc/v-md-editor/lib/codemirror-editor'
import '@kangc/v-md-editor/lib/style/codemirror-editor.css'

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'

// highlightjs
import hljs from 'highlight.js'

// codemirror 编辑器的相关资源
import Codemirror from 'codemirror'
// mode
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/vue/vue'
// edit
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets'
// placeholder
import 'codemirror/addon/display/placeholder'
// active-line
import 'codemirror/addon/selection/active-line'
// scrollbar
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/scroll/simplescrollbars.css'
// style
import 'codemirror/lib/codemirror.css'

VMdEditor.Codemirror = Codemirror
VMdEditor.use(githubTheme, {
  Hljs: hljs
})

VMdPreview.use(githubTheme, {
  Hljs: hljs
})

const app = createApp(App)

app.use(ElIcon)
app.use(ElInput)
app.use(ElRow)
app.use(ElCol)
app.use(ElColorPicker)
app.use(ElSlider)
app.use(ElRadioGroup)
app.use(ElRadio)
app.use(ElButton)
app.use(ElMessage)
app.use(ElDropdown)
app.use(ElDropdownMenu)
app.use(ElDropdownItem)
app.use(ElUpload)
app.use(ElImage)
app.use(ElMessageBox)
app.use(ElSkeleton)

app.use(VMdEditor)
app.use(VMdPreview)

app.use(router).use(store).mount('#app')
