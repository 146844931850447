<template>
  <footer class="footer">
    <div>
      <span>友情链接</span>
      <a href="https://aurthur.cn"
         target="_black">聪聪班长</a>
      <a href="https://mikialex.github.io/"
         target="_black">艺术家马珂</a>
    </div>

    <div>
      <span>© 2021 — 2021 charles</span>
      <span>备案号：陕ICP备 2021006712号-1</span>
    </div>
  </footer>
</template>

<style>
.footer {
  margin-top: 60px;
}

.footer div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  font-size: 14px;
  color: #666;
}

.footer span {
  margin: 0 16px;
}

.footer a {
  color: #42b983;
  text-decoration: none;
  margin: 0 8px;
}
</style>
