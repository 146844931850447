<template>
  <header class="header">
    <div class="header-inner">
      <div class="header-avatar">
        <img src="../../assets/mine.jpeg"
             alt="" />
        <h3>你的指尖，有改变世界的力量</h3>
      </div>

      <div id="nav"
           class="hidden-xs-only">
        <router-link to="/"><i class="el-icon-guide"></i> 主页</router-link>
        <!-- <router-link to="/photo"><i class="el-icon-picture-outline"></i> 照片墙</router-link> -->
        <router-link to="/articles"><i class="el-icon-reading"></i> 文档库</router-link>
        <router-link to="/tools"><i class="el-icon-chicken"></i> 小工具</router-link>
        <router-link to="/about"><i class="el-icon-user"></i> 关于我</router-link>
      </div>
      <div id="nav1"
           class="hidden-sm-and-up">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-more"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link to="/"><i class="el-icon-guide"></i> 主页</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/photo"><i class="el-icon-picture-outline"></i> 照片墙</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/articles"><i class="el-icon-reading"></i> 文档库</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/tools"><i class="el-icon-chicken"></i> 小工具</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/about"><i class="el-icon-user"></i> 关于我</router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>

  <div id="main"
       class="main">
    <router-view />

    <home-footer></home-footer>
  </div>

  <!-- <canvas id="myCanvas"
          style="position:absolute;left:0;top:0;z-index:0;pointer-events:none;" /> -->
</template>

<script>
// import { nextTick } from '@vue/runtime-core'
import 'element-plus/lib/theme-chalk/display.css'
import homeFooter from '../../components/Footer'

export default {
  components: {
    homeFooter
  }
  // setup () {
  //   // 屏幕宽高
  //   let container
  //   let WIDTH
  //   let HEIGHT
  //   // canvas
  //   let canvas
  //   let context
  //   // 圆点数量
  //   let roundCount
  //   // 存放园点属性的数组
  //   const round = []

  //   nextTick(() => {
  //     if (!container) {
  //       container = document.getElementById('app')
  //       WIDTH = container.offsetWidth
  //       HEIGHT = container.offsetHeight

  //       canvas = document.getElementById('myCanvas')
  //       context = canvas.getContext('2d')
  //       roundCount = Math.round(HEIGHT / 20)

  //       // 令 canvas 铺满屏幕
  //       canvas.width = WIDTH
  //       canvas.height = HEIGHT

  //       // 调用初始化函数
  //       init()
  //     }
  //   })

  //   function init () {
  //     for (var i = 0; i < roundCount; i++) {
  //       // eslint-disable-next-line new-cap
  //       round[i] = new roundItem(i, Math.random() * WIDTH, HEIGHT)
  //       round[i].draw()
  //     }
  //     animate()
  //   }

  //   // 构造圆点位置颜色大小等属性
  //   function roundItem (index, x, y) {
  //     this.index = index
  //     // 位置
  //     this.x = x
  //     this.y = y
  //     // 半径
  //     this.r = Math.random() * 9 + 2
  //     // 移动速度与圆点半径成正比
  //     this.vy = this.r / 12
  //     this.vx = (Math.random() - 0.5) * (this.r / 10)
  //     // 颜色
  //     const alpha = (Math.ceil(Math.random() * 10) + 2) * 0.02
  //     this.color = 'rgba(0,0,0,' + alpha + ')'
  //   }

  //   // 画圆点
  //   roundItem.prototype.draw = function () {
  //     context.fillStyle = this.color
  //     context.shadowBlur = this.r * 2
  //     context.beginPath()
  //     context.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false)
  //     context.closePath()
  //     context.fill()
  //   }

  //   // 移动圆点
  //   roundItem.prototype.move = function () {
  //     // // y方向移动速度与圆点半径成正比
  //     // this.y -= this.r / 15

  //     // // x方向移动分两个方向，移动速度与圆点半径成正比
  //     // if (this.index % 2 === 0) {
  //     //   this.x -= Math.random() * 10 / 40
  //     // } else {
  //     //   this.x += Math.random() * 10 / 40
  //     // }
  //     this.x -= this.vx
  //     this.y -= this.vy

  //     // 如果超出范围就把它拉回来
  //     if (this.y <= 0) {
  //       this.y += HEIGHT
  //     }
  //     if (this.x <= 0) {
  //       this.x += WIDTH
  //     }
  //     if (this.x >= WIDTH) {
  //       this.x -= WIDTH
  //     }

  //     this.draw()
  //   }

  //   // 定义动画
  //   function animate () {
  //     context.clearRect(0, 0, WIDTH, HEIGHT)
  //     for (var i in round) {
  //       round[i].move()
  //     }
  //     requestAnimationFrame(animate)
  //   }
  // }
}
</script>

<style scoped>
.header {
  position: relative;
  height: 72px;
  background: #f5f5f5;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 72px;
  max-width: 1200px;
  margin: 0 auto;
}

.header-avatar {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.header-avatar > img {
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 50%;
}

#nav {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#nav1 {
  width: 60px;
  display: flex;
  align-items: center;
}

#nav1 .el-dropdown-link i {
  font-size: 24px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.main {
  position: relative;
  height: calc(100% - 72px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
