import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home/Home.vue'
import Homepage from '../views/homepage/Home.vue'

const routes = [
  {
    path: '/',
    component: Homepage,
    redirect: '/home',
    children: [
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
      },
      {
        path: '/articles',
        name: 'Articles',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/Articles.vue')
      },
      {
        path: '/article',
        name: 'Article',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/Article.vue')
      },
      {
        path: '/newarticle',
        name: 'NewArticle',
        component: () => import(/* webpackChunkName: "article" */ '../views/article/NewArticle.vue')
      },
      {
        path: '/photo',
        name: 'Photo',
        component: () => import(/* webpackChunkName: "photo" */ '../views/photo/Photo.vue')
      },
      {
        path: '/tools',
        name: 'Tools',
        component: () => import(/* webpackChunkName: "photo" */ '../views/tools/Tools.vue')
      },
      {
        path: '/gaoyanggame',
        name: 'Game',
        component: () => import(/* webpackChunkName: "game" */ '../views/game/Index.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
